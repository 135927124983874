/* src/styles/custom.css */
:root {
    --color-background: #121212;
    --color-surface: #1e1e1e;
    --color-primary: #007aff;
    --color-secondary: #5ac8fa;
    --color-text: #ffffff;
    --color-text-secondary: #a0a0a0;
    --color-border: #2c2c2c;
  }
  
  html {
    scroll-behavior: smooth;
  }
  
  .text-gradient {
    background: linear-gradient(45deg, var(--color-primary), var(--color-secondary));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
  }
  
  .model-container {
    width: 100%;
    height: 500px;
  }
  
  .alternating-diagonal-lines {
    position: relative;
  }
  
  .alternating-diagonal-lines::before,
  .alternating-diagonal-lines::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-image: repeating-linear-gradient(
      -45deg,
      transparent,
      transparent 10px,
      rgba(255, 255, 255, 0.1) 10px,
      rgba(255, 255, 255, 0.1) 20px
    );
  }
  
  .alternating-diagonal-lines::after {
    background-image: repeating-linear-gradient(
      -45deg,
      transparent,
      transparent 10px,
      rgba(0, 0, 0, 0.05) 10px,
      rgba(0, 0, 0, 0.05) 20px
    );
    opacity: 0.5;
  }
  
  body[data-theme="dark"] .alternating-diagonal-lines::before {
    background-image: repeating-linear-gradient(
      -45deg,
      transparent,
      transparent 10px,
      rgba(255, 255, 255, 0.05) 10px,
      rgba(255, 255, 255, 0.05) 20px
    );
  }
  
  body[data-theme="dark"] .alternating-diagonal-lines::after {
    background-image: repeating-linear-gradient(
      -45deg,
      transparent,
      transparent 10px,
      rgba(0, 0, 0, 0.1) 10px,
      rgba(0, 0, 0, 0.1) 20px
    );
  }